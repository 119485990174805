.navbar {
  background-color: #3195ff;
  display: flex;
  justify-content: space-between;
  padding: 12px;
  color: white;
  height: 70px;
  width: 100vw;
}

.navbarLogo {
  font-size: 24px;
  font-weight: bold;
}

.navbarLogoImage {
  height: 40px;
  margin-top: 3px;
}

.userInfo {
  display: flex;
  align-items: center;
}

.userName {
  margin-right: 10px;
}

.userIcon {
  height: 40px;
  width: 40px;
}

/* Hides the navbar when the screen size is less than 600px */
@media only screen and (max-width: 600px) {
  .navbar {
    display: none;
    height: 0px;
  }
}

.modal {
  position: absolute;
  top: 60px;
  right: 0;
  width: 300px;
  margin-right: 20px;
}

.modalContent {
  background-color: white;
  padding: 20px;
  border-radius: 15px;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.2);
}

.modalContent ul {
  list-style-type: none;
  padding: 0;
}

.modalContent ul li a {
  text-decoration: none;
  color: black;
  display: block;
  padding: 10px;
}

.modalContent ul li a:hover {
  background-color: #f0f0f0;
}

.modalRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex: 1;
  margin-top: 8px;
}

.modalRow heading h3 {
  color: black;
}

.modalContent button {
  margin-top: 12px;
}
