.cardContainer {
  perspective: 1000px;
  width: 80%;
  max-width: 350px;
  margin: 0 auto;
  position: relative;
  padding: 20px;
  flex: 1;
}

.card {
  width: 100%;
  min-height: 250px;
  position: relative;
  transform-style: preserve-3d;
  transition: transform 0.5s;
  transform: rotateY(0deg);
  font-size: 24px;
}

.card.flipped {
  transform: rotateY(180deg);
}

.cardFront,
.cardBack {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  border: 1px solid #ddd;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

.cardFront {
  background-color: white;
  display: flex;
  flex-direction: column; /* Stack children vertically */
  justify-content: center;
  align-items: center;
  background-color: white;
}

.cardBack {
  transform: rotateY(180deg);
  display: flex;
  background-color: #f7f7f7;
  flex-direction: column; /* Stack children vertically */
  justify-content: center;
  align-items: center;
}

.flipButton {
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #4caf50;
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 5px;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1);
  font-size: 24px;
  transition: background-color 0.3s;
}

.flipButton:hover {
  background-color: #45a049;
}

.flipButton:focus {
  outline: none;
}

.cardFrontContent {
  flex: 1; /* Takes the available vertical space */
  display: flex;
  justify-content: center;
  align-items: center;
}

.volumeControl {
  width: 100%;
  display: flex;
  flex-direction: row; /* Stack children vertically */
  justify-content: center;
  align-items: center;
  padding: 10px;
  margin-top: 0px; /* Add some space between the content and the volume control */
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.spinnerRotate {
  animation: spin 1s linear infinite;
  color: lightgrey;
}
