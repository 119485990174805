* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.AppContainer {
  width: 100vw;
  display: flex;
  align-items: center;
  flex-direction: column;
  background: none;
}

.App {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  /* Add some padding to prevent contents touching screen edge */
  background: linear-gradient(135deg, #f5f5f5, #cacaca);
  flex-direction: column;
  min-height: 100vh;
}

.logoImage {
  width: 300px;
  resize: both;
  margin-top: 20px;
}

.phone {
  max-width: 480px;
  width: 480px;
  min-height: calc(100vh - 180px);
  max-height: calc(100vh - 180px);
  border-radius: 30px;
  background-color: #3195ff;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  padding-top: 20px;
  margin-top: 20px;
}

.chatlink {
  color: #333;
  font-size: x-large;
  margin-bottom: 30px;
}

.footerLink {
  margin: 10px;
  color: #333;
  font-weight: bold;
  text-decoration: none;
}

.blue-button {
  background-color: #0000a0;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  width: 100%;
  cursor: pointer;
  font-size: 16px;
}

.blue-button nav {
  margin-top: 10px;
}

.checkout-button {
  background-color: #0000a0;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
}

.white-button {
  background-color: #ffffff;
  color: rgb(0, 0, 0);
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  width: 100%;
  cursor: pointer;
  font-size: 16px;
}

.chat {
  flex: 1;
  overflow-y: auto; /* Enable vertical scrolling */
  overflow-x: hidden; /* Disable horizontal scrolling */
  padding: 16px;
  background-color: #ffffff;
  display: flex; /* Add this */
  flex-direction: column; /* Add this */
  border-top-left-radius: 24px;
  border-top-right-radius: 24px;
}

.debug {
  font-size: 0.8em;
  text-align: center;
  padding-bottom: 5px;
}

.input-area {
  display: flex;
  padding: 10px;
  background-color: #fff;
  border-bottom-left-radius: 24px;
  border-bottom-right-radius: 24px;
  /*border-top: 1px solid #ddd;*/
}

.message-input {
  flex: 1;
  padding: 10px;
  /*border: 1px solid #ddd;*/
  background: linear-gradient(45deg, #f5f5f5, #ececec);
  border-radius: 20px;
  border: 0;
}

.submit-button {
  margin-left: 10px;
  padding: 10px 20px;
  border: none;
  background-color: #3195ff;
  color: #fff;
  border-radius: 20px;
  cursor: pointer;
}

.message {
  margin-bottom: 10px;
  padding: 10px;
  border-radius: 14px;
  max-width: 80%; /* This sets the maximum width to 80% of the parent */
  align-self: flex-start; /* This aligns all bubbles to the left */
}

.message.user {
  background-color: #8ac3ff;
  align-self: flex-end; /* This aligns user messages to the right */
  border-bottom-right-radius: 0px;
}

.message.options {
  background-color: rgb(248, 248, 248);
  align-self: flex-end; /* This aligns user messages to the right */
  margin-bottom: 8px;
  padding: 8px;
  padding-top: 4px;
  border-radius: 14px;
  border-style: solid;
  border-width: 1px;
  border-color: #bdbdbd;
  font-style: italic;
  font-size: 0.9em;
}

.message.options:hover {
  background-color: #8ac3ff; /* Change to your desired color */
}

.message.character {
  background-color: rgb(219, 219, 219);
  color: #000000;
  align-self: flex-start; /* This aligns character messages to the left */
  border-bottom-left-radius: 0px;
}

.message-user {
  font-size: 0.8em;
  font-weight: bold;
}

.message-text {
  margin-top: 5px;
  font-size: 1em;
}

.message-translation {
  margin-top: 2px;
  font-size: 0.7em;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.5s ease-in-out;
}

.message-translation.show {
  max-height: 100px; /* Or an appropriate height */
}

.message.character.typing {
  background-color: #aaa;
  /* ... other styles ... */
}

.form-container {
  display: flex;
  flex-direction: column;
  width: 480px;
  margin-bottom: 30px;
  padding: 20px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
  background: #ffffff;
  border-radius: 10px;
}

.privacy-container {
  display: flex;
  flex-direction: column;
  max-width: 880px;
  margin: 30px;
  padding: 20px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
  background: #ffffff;
  border-radius: 10px;
}
.privacy-container h1 {
  font-size: 2em;
  font-weight: bold;
  color: #333;
  margin-bottom: 20px;
}

.privacy-container h2 {
  font-size: 1.5em;
  font-weight: bold;
  color: #666;
  margin-top: 20px;
  margin-bottom: 10px;
}

.privacy-container h3 {
  font-size: 1.2em;
  font-weight: bold;
  color: #999;
  margin-top: 15px;
  margin-bottom: 5px;
}

.privacy-container p {
  font-size: 1em;
  color: #555;
  line-height: 1.5;
  margin-bottom: 10px;
}

.form-field {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.form-field input,
.form-field select,
.form-field textarea {
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
}

.form-field textarea {
  height: 100px;
}

.form-field label {
  margin-bottom: 5px;
  font-weight: bold;
}

@media screen and (max-width: 480px) {
  .App {
    /*height: calc(100vh-20px);*/
    height: 100%;
    width: 100%;
    margin: 0;
    padding: 0;
    justify-content: flex-start;
  }

  .phone {
    margin: 0;
    padding: 10;
    border-radius: 0;
    width: 100vw;
    min-height: 100vh;
    max-height: 100vh;
  }

  .form-container {
    max-width: 480px;
    width: 90%;
  }

  .chat {
    border-radius: 0;
  }

  .input-area {
    display: flex;
    border-radius: 0;
    padding-bottom: 10;
  }
}

@keyframes dot {
  0% {
    opacity: 0;
  }
  20% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.typing-indicator {
  display: flex;
  justify-content: space-around;
  width: 50px;
}

.typing-indicator > span {
  background-color: #575757;
  border-radius: 50%;
  width: 8px;
  height: 8px;
  display: inline-block;
}

.typing-indicator > span:nth-child(1) {
  animation: dot 1s infinite;
  animation-delay: 0s;
}

.typing-indicator > span:nth-child(2) {
  animation: dot 1s infinite;
  animation-delay: 0.2s;
}

.typing-indicator > span:nth-child(3) {
  animation: dot 1s infinite;
  animation-delay: 0.4s;
}

.homeSettingsIconContainer {
  text-align: right;
  align-items: end;
}

.settingsEnglish {
  font-size: smaller;
  margin: 10px;
}

.settingsIcon {
  float: right;
  font-size: 24px;
  color: white;
  cursor: pointer;
  margin-right: 20px;
  margin-top: -5px;
  margin-bottom: 10px;
}

.homeSettingsIcon {
  font-size: 24px;
  color: rgb(0, 0, 0);
  cursor: pointer;
  align-self: flex-end;
}

.homeHeaderContainer {
  flex-direction: row;
  display: flex;
  flex: 1;
  justify-content: space-between;
}

.headerContents {
  clear: both;
  padding: 20px;
  color: #fff;
  flex: 1;
  min-height: 60px;
}

.headerContents label {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.headerContainer {
  /* Adjust this according to your design */
  transition: height 1s ease-out;
  color: #fff;
}

.headerContainerRealtime {
  /* Adjust this according to your design */
  transition: height 1s ease-out;
  color: #fff;
  flex-direction: row;
  justify-content: space-between;
}

.contenActionsRealtime {
  align-items: center;
  display: flex;
  justify-content: space-around;
  background-color: white;
}

.chatLogoImage {
  height: 24px;
  margin-left: 12px;
  resize: both;
  margin-top: -6px;
}

.userTokens {
  flex: 1;
  margin-left: 20px;
}

/*/grading*/
.gradeButton {
  border: none;
  padding: 10px 15px;
  margin: 5px;
  color: white;
  cursor: pointer;
  transition: opacity 0.3s;
}

.gradeButton:hover {
  opacity: 0.8;
}
.disabledGrade {
  opacity: 1;
  background-color: rgb(143, 143, 143);
}
.grade0,
.grade1 {
  background-color: red;
}

.grade2,
.grade3 {
  background-color: yellow;
  color: black; /* Setting text color to black for better readability on a yellow background */
}

.grade4,
.grade5 {
  background-color: green;
}
.disabledGrade {
  opacity: 1;
  background-color: rgb(143, 143, 143);
}

.headerContainerRealtime {
  display: flex;
  justify-content: space-between; /* Aligns children to the sides */
  align-items: center; /* Centers children vertically */
  padding: 10px; /* Add padding as needed */
  /* Add any other styling you need for the header container */
}

.custom-mic-button {
  width: 120px;
  height: 120px;
  background-color: #8ec5ff; /* Or any color you want */
  border-radius: 50%;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2); /* Optional: adds a shadow effect */
  user-select: none;
}

.custom-mic-button:active,
.custom-mic-button.recording {
  /* Add a new class for the recording state */
  background-color: rgb(202, 0, 0); /* Change to red when active/pressed */
}
